
body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.material-symbols-outlined {
  margin-top: 2.5px;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.material-symbols-fill {
  font-variation-settings: 'FILL' 1;
}


